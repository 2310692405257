import commonAction from 'actions/commonAction';
import IdeonCarriersApi from 'api/generated/IdeonCarriersApi';

export const TOGGLE_WHITELISTED_IDEON_CARRIER_ARCHIVED_ACTION =
    'TOGGLE_WHITELISTED_IDEON_CARRIER_ARCHIVED';

export const toggleWhitelistedIdeonCarrierArchived = (id: string, isArchived: boolean) =>
    commonAction(
        async () => new IdeonCarriersApi().toggleWhitelistedIdeonCarrierArchived(id),
        TOGGLE_WHITELISTED_IDEON_CARRIER_ARCHIVED_ACTION,
        {
            toastErrorMessage: `Failed to ${
                isArchived ? 'un-archive' : 'archive'
            } whitelisted Ideon carrier`,
            toastSuccessMessage: `Successfully ${
                isArchived ? 'un-archived' : 'archived'
            } whitelisted Ideon carrier`,
        }
    );
