import commonAction from 'actions/commonAction';
import IdeonCarriersApi from 'api/generated/IdeonCarriersApi';

export const GET_WHITELISTED_CARRIERS_ACTION = 'GET_WHITELISTED_CARRIERS';

export const getWhitelistedCarriers = (year: number) =>
    commonAction(
        async () => new IdeonCarriersApi().getWhitelistedCarriers(year),
        GET_WHITELISTED_CARRIERS_ACTION,
        {
            toastErrorMessage: 'Failed to get whitelisted carriers',
        }
    );
