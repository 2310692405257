import commonAction from 'actions/commonAction';
import IdeonCarriersApi from 'api/generated/IdeonCarriersApi';

export const GET_SUPPORTED_OFF_EXCHANGE_STATES_ACTION = 'GET_SUPPORTED_OFF_EXCHANGE_STATES';

export const getSupportedOffExchangeStates = (year: number) =>
    commonAction(
        async () => new IdeonCarriersApi().getSupportedOffExchangeStates(year),
        GET_SUPPORTED_OFF_EXCHANGE_STATES_ACTION,
        {
            toastErrorMessage: 'Failed to get supported off-exchange states',
        }
    );
