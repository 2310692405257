import commonAction from 'actions/commonAction';
import IdeonCarriersApi from 'api/generated/IdeonCarriersApi';

export const DELETE_WHITELISTED_CARRIER = 'DELETE_WHITELISTED_CARRIER';

export const deleteWhitelistedCarrier = (id: string) =>
    commonAction(
        async () => new IdeonCarriersApi().deleteWhitelistedCarrier(id),
        DELETE_WHITELISTED_CARRIER,
        {
            toastErrorMessage: 'Failed to delete whitelisted carrier',
            toastSuccessMessage: 'Whitelisted carrier deleted successfully',
        }
    );
