import commonAction from 'actions/commonAction';
import IdeonCarriersApi from 'api/generated/IdeonCarriersApi';
import {
    AddCarrierDto,
    AddWhitelistedCarriersRequest,
    IAddWhitelistedCarriersRequest,
} from 'api/generated/models';

export const ADD_WHITELISTED_CARRIERS_ACTION = 'ADD_WHITELISTED_CARRIERS';

export const addWhitelistedCarriers = (
    addedCarriers: AddCarrierDto[],
    stateAbbrev: string,
    year: number
) => {
    const request: IAddWhitelistedCarriersRequest = { addedCarriers, stateAbbrev, year };
    return commonAction(
        async () =>
            new IdeonCarriersApi().addWhitelistedCarriers(
                new AddWhitelistedCarriersRequest(request)
            ),
        ADD_WHITELISTED_CARRIERS_ACTION,
        {
            toastErrorMessage: 'Failed to add new whitelisted carrier(s)',
            toastSuccessMessage: 'Successfully added new whitelisted carrier(s)',
        }
    );
};
