import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const TRANSFER_MEMBERS_TO_DIFFERENT_TEAM_ACTION = 'TRANSFER_MEMBERS_TO_DIFFERENT_TEAM';

export const transferMembersToDifferentTeam = (selectedMembers: string[], newTeamId: string) =>
    commonAction(
        async () => new UsersApi().transferMembersToDifferentTeam(newTeamId, selectedMembers),
        TRANSFER_MEMBERS_TO_DIFFERENT_TEAM_ACTION,
        {
            toastErrorMessage: 'Unable to transfer members to a different team.',
            toastInitialMessage:
                'Transferring members to a different team.  This may take a while...',
        }
    );
